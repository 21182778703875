import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    ActivateOffline,
    BuyProSuccess,
    DeactivateOffline,
    Licensing,
    LicensingActivateLicenseOnline,
    LicensingGroupAllocation,
    LicensingGroupAllocationView,
    LicensingTenantConfigure,
    LicensingUpgradeToEnterprise,
    LicensingUserAddLicenseAllocation,
    LicensingUserEditLicenseAllocation,
    LicensingUserLicenseInheritance,
    ViewCancelLicensePendingChange,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import useIsOnPrem from '../../common/hooks/useIsOnPrem';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import CheckGuard from '../../container/helpers/CheckGuard';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import { accountType as accountTypeSelector } from '../../store/selectors';

const Licenses = React.lazy(() => import('./Licenses'));
const AddEditGroupAllocationComponent = React.lazy(() => import('./subcomponents/AddEditGroupAllocationComponent'));
const AddUserAllocationComponent = React.lazy(() => import('./subcomponents/AddUserAllocationComponent'));
const EditUserAllocationComponent = React.lazy(() => import('./subcomponents/EditUserAllocationComponent'));
const InheritedLicenseComponent = React.lazy(() => import('./subcomponents/InheritedLicenseComponent'));
const ViewUserAllocationsInGroupComponent = React.lazy(() => import('./subcomponents/ViewUserAllocationsInGroupComponent'));
const UpgradeToEnterpriseDialogComponent = React.lazy(() => import('./UpgradeToEnterpriseDialogComponent'));
const ConfigureTenantLicensesComponent = React.lazy(() => import('../tenants/subcomponents/ConfigureTenantLicenseComponent'));
const EcommerceManageLicensePendingChangeComponent = React.lazy(() => import('../ecommerce/EcommerceManageLicensePendingChangeComponent'));
const ActivateLicenseOfflineComponent = React.lazy(() => import('./subcomponents/ActivateLicenseOfflineComponent'));
const EcommerceMonthlyUpsellComponent = React.lazy(() => import('../ecommerce/EcommerceMonthlyUpsellComponent'));
const ActivateLicenseOnlineComponent = React.lazy(() => import('./subcomponents/ActivateLicenseOnlineComponent'));
const EcommerceSuccessComponent = React.lazy(() => import('./subcomponents/EcommerceSuccessComponent'));

const DeactivateLicenseOfflineComponent = React.lazy(() => import('./subcomponents/DeactivateLicenseOfflineComponent'));

const LicensesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminLicenses,
            name: translate({ id: 'CLIENT_LICENSES' }),
        },
    ], [ organizationName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {children}
    </BreadcrumbProvider>;
};

const LicensesFeatureFlagCheckComponent: React.FC<{ type: 'pending' | 'offline' | 'online' | 'success' }> = ({ type }) => {
    const EnableEcommerceIntegration = useFeatureFlagValue(Features.EnableEcommerceIntegration.name);
    const EnableAirgappedLicenseActivation = useFeatureFlagValue(Features.EnableAirgappedLicenseActivation.name);

    const {
        isAccountLicenseEnterpriseOrPro, isPro,
    } = useCheckLicense();

    const accountType = useSelector(accountTypeSelector);
    const isOnPrem = useIsOnPrem();

    if (type === 'pending') {
        return CheckGuard(
            EnableEcommerceIntegration && isAccountLicenseEnterpriseOrPro(accountType),
            <EcommerceManageLicensePendingChangeComponent />,
        );
    }

    if (type === 'offline') {
        return CheckGuard(
            EnableAirgappedLicenseActivation || isOnPrem,
            <ActivateLicenseOfflineComponent />,
        );
    }

    if (type === 'online') {
        return isPro
            ? <EcommerceMonthlyUpsellComponent />
            : <ActivateLicenseOnlineComponent />;
    }

    if (type === 'success') {
        return CheckGuard(
            EnableEcommerceIntegration,
            <EcommerceSuccessComponent />,
        );
    }

    return undefined;
};

export const AdminLicenseRoutes: UiRouteObject = {
    path: routePaths.adminLicenses,
    element: <LicensesProvider>
        <UiSuspensefulOutlet />
    </LicensesProvider>,
    children: [
        {
            path: routePaths.adminLicenses,
            redirectUrls: [ Licensing ],
            element: <Licenses>
                <UiSuspensefulOutlet />
            </Licenses>,
            children: [
                {
                    path: routePaths.adminLicensesUpgrade,
                    redirectUrls: [ LicensingUpgradeToEnterprise ],
                    element: <UpgradeToEnterpriseDialogComponent />,
                },
                {
                    path: routePaths.adminLicensesPending,
                    redirectUrls: [ ViewCancelLicensePendingChange ],
                    element: <LicensesFeatureFlagCheckComponent type="pending" />,
                },
                {
                    path: routePaths.adminLicensesOffline,
                    redirectUrls: [ ActivateOffline ],
                    element: <LicensesFeatureFlagCheckComponent type="offline" />,
                },
                {
                    path: routePaths.adminLicensesOnline,
                    redirectUrls: [ LicensingActivateLicenseOnline ],
                    element: <LicensesFeatureFlagCheckComponent type="online" />,
                },
                {
                    path: routePaths.adminLicensesOfflineDeactivate,
                    redirectUrls: [ DeactivateOffline ],
                    element: <DeactivateLicenseOfflineComponent />,
                },
                {
                    path: routePaths.adminLicensesSuccess,
                    redirectUrls: [ BuyProSuccess ],
                    element: <LicensesFeatureFlagCheckComponent type="success" />,
                },
                {
                    path: routePaths.adminLicensesUsersAdd,
                    redirectUrls: [ LicensingUserAddLicenseAllocation ],
                    element: <AddUserAllocationComponent />,
                },
                {
                    path: routePaths.adminLicensesUsersEdit,
                    redirectUrls: [ LicensingUserEditLicenseAllocation ],
                    element: <EditUserAllocationComponent />,
                },
                {
                    path: routePaths.adminLicensesUsersInherit,
                    redirectUrls: [ LicensingUserLicenseInheritance ],
                    element: <InheritedLicenseComponent />,
                },
                {
                    path: routePaths.adminLicensesGroups,
                    redirectUrls: [ LicensingGroupAllocationView ],
                    element: <ViewUserAllocationsInGroupComponent />,
                },
                {
                    path: routePaths.adminLicensesGroupsAdd,
                    redirectUrls: [ `${LicensingGroupAllocation}/add` ],
                    element: <AddEditGroupAllocationComponent type="add" />,
                },
                {
                    path: routePaths.adminLicensesGroupsEdit,
                    redirectUrls: [ `${LicensingGroupAllocation}/edit` ],
                    element: <AddEditGroupAllocationComponent type="edit" />,
                },
                {
                    path: routePaths.adminLicensesTenantEdit,
                    redirectUrls: [ LicensingTenantConfigure ],
                    element: <ConfigureTenantLicensesComponent />,
                },
            ],
        },
    ],
};
