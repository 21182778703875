export const Entitlements = {
    IpRestrictions: 'Platform.IpRestrictions',
    CustomerManagedKey: 'Platform.CustomerManagedKey',
    Insights: 'Insights.FullMode',
    PlatformSapEnbled: 'Platform.SapEnabled',
    SessionPolicy: 'Platform.SessionPolicy',
    AccessRestriction: 'Platform.AccessRestrictionPolicy',
    CheckAccess: 'Platform.CheckAccess',
    UserConsent: 'Platform.UserConsentPolicy',
    AiTrustLayerByoLlm: 'AITL.BYOLLM',
} as const;

export type Entitlement = typeof Entitlements[keyof typeof Entitlements];

export const entitlementsToLegacyCodes: { [key: string]: string } = {
    'Insights.FullMode': 'ANL',
    'AutomationHub.Enabled': 'AHUB',
    'ProcessMining.Enabled': 'PM',
    'TestManager.Enabled': 'TMH',
};
