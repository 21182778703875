import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useCallback } from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

interface IQuotaConfig {
    enabled: boolean;
    value: number;
}

type ILicenseGenericWithCode = { code: string; quotaConfig?: IQuotaConfig; inUse?: number } & Record<string, any>;

interface QuotaManagementProps {
    userBundle: ILicenseGenericWithCode;
}

interface IUserBundleQuota {
    userBundleLicense: string;
    quotaConfig?: IQuotaConfig;
}

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        quotaContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            marginLeft: '30px',
            marginTop: '4px',
        },
        quotaInput: {
            width: '50px',
            marginLeft: '4px',
            '& input': { padding: '6px 4px' },
            '& .MuiOutlinedInput-notchedOutline': { border: `1px solid ${theme.palette.semantic.colorBorder}` },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': { border: `1px solid ${theme.palette.semantic.colorBorderDisabled}` },
        },
        quotaText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            whiteSpace: 'nowrap',
        },
        quotaInUse: {
            fontSize: '12px',
            color: theme.palette.semantic.colorSuccessText,
            marginLeft: '36px',
            marginTop: '4px',
        },
        switchContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
}));

export const QuotaManagementComponent: React.FC<QuotaManagementProps> = ({ userBundle }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        getValues, control, setValue, watch,
    } = useFormContext();

    const handleQuotaValueChange = useCallback(
        (newValue: number) => {
            const { userBundleQuotas = [] } = getValues();
            const currentQuota = userBundleQuotas.find((q: IUserBundleQuota) => q.userBundleLicense === userBundle.code);
            const isEnabled = currentQuota?.quotaConfig?.enabled ?? false;

            const filteredQuotas = userBundleQuotas.filter((q: IUserBundleQuota) => q.userBundleLicense !== userBundle.code);
            const newQuotas = [
                ...filteredQuotas,
                {
                    userBundleLicense: userBundle.code,
                    quotaConfig: {
                        enabled: isEnabled,
                        value: newValue,
                    },
                },
            ];
            setValue('userBundleQuotas', newQuotas, { shouldDirty: true });
        },
        [ getValues, setValue, userBundle.code ]
    );

    const handleQuotaSwitch = useCallback(
        (isEnabled: boolean) => {
            const currentQuotas = getValues().userBundleQuotas || [];
            const currentQuota = currentQuotas.find((q: IUserBundleQuota) => q.userBundleLicense === userBundle.code);
            const currentValue = currentQuota?.quotaConfig?.value ?? 0;

            const finalValue = isEnabled
                ? (currentQuota?.quotaConfig?.value && currentQuota.quotaConfig.value > 0
                    ? currentQuota.quotaConfig.value
                    : (currentQuota?.quotaConfig?.lastValue || 1))
                : 0;

            const updatedQuotas = currentQuotas.map((q: IUserBundleQuota) =>
                q.userBundleLicense === userBundle.code
                    ? {
                        ...q,
                        quotaConfig: {
                            ...q.quotaConfig,
                            enabled: isEnabled,
                            value: finalValue,
                            ...((!isEnabled && currentValue > 0) ? { lastValue: currentValue } : {}),
                        },
                    }
                    : q
            );

            setValue('userBundleQuotas', updatedQuotas, { shouldDirty: true });
        },
        [ getValues, setValue, userBundle.code ]
    );

    const isQuotaEnabled = useCallback(
        (quotas: IUserBundleQuota[] = []) => quotas.find(q => q.userBundleLicense === userBundle.code)?.quotaConfig?.enabled ?? false,
        [ userBundle.code ]
    );

    const getQuotaValue = useCallback(
        (quotas: IUserBundleQuota[] = []) => quotas.find(q => q.userBundleLicense === userBundle.code)?.quotaConfig?.value ?? 0,
        [ userBundle.code ]
    );

    return (
        <div className={classes.quotaContainer}>
            <div className={classes.switchContainer}>
                <Controller
                    name='userBundleQuotas'
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            key={`label-${userBundle.code}`}
                            control={
                                <Switch
                                    size="small"
                                    checked={isQuotaEnabled(field.value)}
                                    onChange={(e) => handleQuotaSwitch(e.target.checked)}
                                    data-cy="quota-switch"
                                />
                            }
                            label={
                                <UiText
                                    className={classes.quotaText}
                                    data-cy="quota-name">
                                    {translate({ id: `CLIENT_CONFIGURE_GROUP_QUOTA` })}
                                </UiText>
                            }
                        />
                    )}
                />
                {isQuotaEnabled(watch('userBundleQuotas')) && (
                    <UiText
                        className={classes.quotaInUse}
                        data-cy="quota-in-use">
                        {userBundle.inUse}
                        {' '}
                        {translate({ id: 'CLIENT_CONFIGURE_GROUP_QUOTA_IN_USE' })}
                    </UiText>
                )}
            </div>
            <Controller
                name='userBundleQuotas'
                control={control}
                render={({ field }) => (
                    <div>
                        <TextField
                            className={classes.quotaInput}
                            size="small"
                            type="number"
                            disabled={!isQuotaEnabled(field.value)}
                            value={getQuotaValue(field.value)}
                            onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10) || 0;
                                handleQuotaValueChange(newValue);
                            }}
                            inputProps={{ min: 1 }}
                        />
                    </div>
                )}
            />
        </div>
    );
};
